import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import TrackableMenuItem from "storefront/components/Page/Header/TrackableMenuItem";
import TrackableMenuButtonItem from "storefront/components/Page/Header/TrackableMenuButtonItem";
import { signOut } from "storefront/components/SignOutButton";

import styles from "./SubItems.module.scss";

type Props = {
  label: string;
  href: string;
};

/**
 * @name GlobalHeader.SlideOutMenu.Help.SubItems.SubItem
 */
const SubItem = ({ label, href }: Props) => (
  <TrackableMenuItem
    href={href}
    label={label}
    className={styles.subItem}
    from="global_header"
  />
);

/**
 * @name GlobalHeader.SlideOutMenu.Items.Help.SubItems.Help
 */
export const Help = () => {
  const { t } = useTranslation("helpCenterUrls");
  return <SubItem label="Help" href={t("helpCenterHome")} />;
};

/**
 * @name GlobalHeader.SlideOutMenu.Items.Help.SubItems.About
 */
export const About = () => <SubItem label="About" href="/about" />;

/**
 * @name GlobalHeader.SlideOutMenu.Items.Help.SubItems.Accessibility
 */
export const Accessibility = () => (
  <SubItem label="Accessibility" href="/about/accessibility" />
);

/**
 * @name GlobalHeader.SlideOutMenu.Items.Help.SubItems.Terms
 */
export const Terms = () => <SubItem label="Terms" href="/about/terms" />;

/**
 * @name GlobalHeader.SlideOutMenu.Items.Help.SubItems.Privacy
 */
export const Privacy = () => <SubItem label="Privacy" href="/about/privacy" />;

/**
 * @name GlobalHeader.SlideOutMenu.Items.Help.SubItems.SignOut
 */
export const SignOut = () => (
  <TrackableMenuButtonItem
    label="Sign Out"
    onClick={signOut}
    className={cn(styles.subItem, styles.signOut)}
  />
);
