import React from "react";

import {
  Content,
  ContentContainer,
  ContentHeader,
  ContentLink,
  ContentColumn,
  ContentCTA,
} from "../Content";

const POPULAR_DESIGNERS: {
  slug: string;
  name: string;
}[] = [
  {
    slug: "chrome-hearts",
    name: "Chrome Hearts",
  },
  {
    slug: "balenciaga",
    name: "Balenciaga",
  },
  {
    slug: "maison-margiela",
    name: "Maison Margiela",
  },
  {
    slug: "rick-owens",
    name: "Rick Owens",
  },
  {
    slug: "supreme",
    name: "Supreme",
  },
  {
    slug: "vintage",
    name: "Vintage",
  },
  {
    slug: "louis-vuitton",
    name: "Louis Vuitton",
  },
  {
    slug: "carhartt",
    name: "Carhartt",
  },
  {
    slug: "vetements",
    name: "Vetements",
  },
  {
    slug: "acne-studios",
    name: "Acne Studios",
  },
  {
    slug: "hysteric-glamour",
    name: "Hysteric Glamour",
  },
  {
    slug: "polo-ralph-lauren",
    name: "Polo Ralph Lauren",
  },
];

const DesignersDropdown = () => {
  const columns = Math.ceil(POPULAR_DESIGNERS.length / 6);

  return (
    <Content>
      <ContentHeader>Shop Popular Designers</ContentHeader>
      <ContentContainer>
        {[...Array(columns)].map((_, index) => (
          <ContentColumn key={index}>
            {POPULAR_DESIGNERS.slice(index * 6, index * 6 + 6).map(
              (designer) => (
                <ContentLink
                  key={designer.slug}
                  href={`/designers/${designer.slug}`}
                >
                  {designer.name}
                </ContentLink>
              ),
            )}
          </ContentColumn>
        ))}
      </ContentContainer>
      <ContentCTA href="/designers">See All Designers</ContentCTA>
    </Content>
  );
};

export default DesignersDropdown;
