import React from "react";

import { Department } from "storefront/Department";
import {
  Content,
  ContentContainer,
  ContentHeader,
  ContentLink,
  ContentColumn,
  ContentCTA,
} from "../Content";

const MENSWEAR_CATEGORIES: {
  slug: string;
  name: string;
}[] = [
  {
    slug: "short-sleeve-t-shirts",
    name: "T-shirts",
  },
  {
    slug: "hoodies",
    name: "Hoodies",
  },
  {
    slug: "shirts-button-ups",
    name: "Shirts",
  },
  {
    slug: "polos",
    name: "Polos",
  },
  {
    slug: "sweaters-knitwear",
    name: "Sweaters",
  },
  {
    slug: "denim",
    name: "Denim",
  },
  {
    slug: "casual-pants",
    name: "Casual Pants",
  },
  {
    slug: "sweatpants-joggers",
    name: "Sweatpants",
  },
  {
    slug: "low-top-sneakers",
    name: "Sneakers",
  },
  {
    slug: "boots",
    name: "Boots",
  },
  {
    slug: "jewelry-watches",
    name: "Watches",
  },
  {
    slug: "hats",
    name: "Hats",
  },
];

const WOMENSWEAR_CATEGORIES: {
  slug: string;
  name: string;
}[] = [
  {
    slug: "short-sleeve-t-shirts",
    name: "Blouses",
  },
  {
    slug: "hoodies",
    name: "Hoodies",
  },
  {
    slug: "sweaters-knitwear",
    name: "Sweaters",
  },
  {
    slug: "crop-tops",
    name: "Crop Tops",
  },
  {
    slug: "jackets",
    name: "Jackets",
  },
  {
    slug: "coats",
    name: "Coats",
  },
  {
    slug: "jeans",
    name: "Jeans",
  },
  {
    slug: "dresses",
    name: "Dresses",
  },
  {
    slug: "boots",
    name: "Boots",
  },
  {
    slug: "low-top-sneakers",
    name: "Sneakers",
  },
  {
    slug: "shoulder-bags",
    name: "Shoulder Bags",
  },
  {
    slug: "luggage-travel",
    name: "Luggage",
  },
];

type CategoryDropdownProps = {
  department: Department;
};

const CategoryDropdown = ({ department }: CategoryDropdownProps) => {
  const departmentCategories =
    department === "menswear" ? MENSWEAR_CATEGORIES : WOMENSWEAR_CATEGORIES;
  const columns = Math.ceil(departmentCategories.length / 6);

  const categoryBaseLink =
    department === "menswear" ? "/categories" : "/categories/womenswear";

  return (
    <Content>
      <ContentHeader>Shop By Category</ContentHeader>
      <ContentContainer>
        {[...Array(columns)].map((_, index) => (
          <ContentColumn key={index}>
            {departmentCategories
              .slice(index * 6, index * 6 + 6)
              .map((category) => (
                <ContentLink
                  key={category.slug}
                  href={`${categoryBaseLink}/${category.slug}`}
                >
                  {category.name}
                </ContentLink>
              ))}
          </ContentColumn>
        ))}
      </ContentContainer>
      <ContentCTA href={`${categoryBaseLink}/all`}>
        See All Categories
      </ContentCTA>
    </Content>
  );
};

export default CategoryDropdown;
