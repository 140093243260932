import React from "react";
import classnames from "classnames";

import { Typography } from "storefront/ui/typography";
import useAnalytics from "storefront/hooks/useAnalytics";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import styles from "./Content.module.scss";

export const ContentHeader = ({ children }: { children: React.ReactNode }) => (
  <Typography className={styles.header} variant="headline">
    {children}
  </Typography>
);

export const ContentLink = ({
  children,
  href,
}: {
  children: string;
  href: string;
}) => {
  const { track } = useAnalytics();

  const trackClick = (name: string) => {
    const from = "sub_header_designer_dropdown";
    track(buttonClicked(name, from));
    track(navigationClicked(name, from));
  };

  return (
    <a className={styles.link} href={href} onClick={() => trackClick(children)}>
      <Typography variant="callout">{children}</Typography>
    </a>
  );
};

export const ContentContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className={styles.container}>{children}</div>;
};

export const ContentColumn = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.column}>{children}</div>;
};

export const Content = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.content}>{children}</div>;
};

export const ContentCTA = ({
  children,
  href,
}: {
  children: string;
  href: string;
}) => {
  return (
    <a className={classnames(styles.link, styles.cta)} href={href}>
      <Typography variant="button-sm">{children}</Typography>
    </a>
  );
};
