import * as React from "react";
import {
  Root as NavigationMenu,
  List as NavigationMenuList,
  Item as NavigationMenuItem,
  Trigger as NavigationMenuTrigger,
  Content as BaseNavigationMenuContent,
  Viewport as NavigationMenuViewport,
  Link as NavigationMenuLink,
} from "@radix-ui/react-navigation-menu";
import cn from "classnames";

import { Typography } from "storefront/ui/typography";
import { MENSWEAR, WOMENSWEAR } from "storefront/Department";
import CaretIcon from "./components/CaretIcon";

import DesignersDropdown from "./components/DesignersDropdown";
import CategoryDropdown from "./components/CategoryDropdown";

import styles from "./MerchandisingMenu.module.scss";

const NAV_ITEM_IDS = {
  designers: "designers",
  cybersale: "cybersale",
  holidaygifts: "holidaygifts",
  staffpicks: "staffpicks",
  sneakers: "sneakers",
  collections: "collections",
  editorial: "editorial",
  menswear: "menswear",
  womenswear: "womenswear",
};

type BaseNavigationMenuContentProps = React.ComponentProps<
  typeof BaseNavigationMenuContent
> & {
  active: boolean;
};

const NavigationMenuContent = ({
  key,
  forceMount: _,
  className,
  active,
  ...rest
}: BaseNavigationMenuContentProps) => {
  return (
    <BaseNavigationMenuContent
      key={String(key)}
      forceMount
      {...rest}
      className={cn(className, {
        [styles.hidden]: !active,
      })}
    />
  );
};

const MerchandisingMenu = () => {
  const [value, setValue] = React.useState<string | undefined>(undefined);
  return (
    <NavigationMenu
      value={value}
      onValueChange={setValue}
      className={styles.root}
    >
      <NavigationMenuList className={styles.list}>
        <NavigationMenuItem value={NAV_ITEM_IDS.designers}>
          <NavigationMenuTrigger className={cn(styles.item, styles.trigger)}>
            <Typography variant="title-sm">DESIGNERS</Typography>{" "}
            <CaretIcon className={styles.caret} aria-hidden="true" />
          </NavigationMenuTrigger>
          <NavigationMenuContent
            active={value === NAV_ITEM_IDS.designers}
            className={styles.content}
          >
            <DesignersDropdown />
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem value={NAV_ITEM_IDS.menswear}>
          <NavigationMenuTrigger className={cn(styles.item, styles.trigger)}>
            <Typography variant="title-sm">MENSWEAR</Typography>{" "}
            <CaretIcon className={styles.caret} aria-hidden="true" />
          </NavigationMenuTrigger>
          <NavigationMenuContent
            active={value === NAV_ITEM_IDS.menswear}
            className={styles.content}
          >
            <CategoryDropdown department={MENSWEAR} />
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem value={NAV_ITEM_IDS.womenswear}>
          <NavigationMenuTrigger className={cn(styles.item, styles.trigger)}>
            <Typography variant="title-sm">WOMENSWEAR</Typography>{" "}
            <CaretIcon className={styles.caret} aria-hidden="true" />
          </NavigationMenuTrigger>
          <NavigationMenuContent
            active={value === NAV_ITEM_IDS.womenswear}
            className={styles.content}
          >
            <CategoryDropdown department={WOMENSWEAR} />
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink className={styles.item} href="/sneakers">
            <Typography variant="title-sm">SNEAKERS</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink className={styles.item} href="/shop/staff-picks">
            <Typography variant="title-sm">STAFF PICKS</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink className={styles.item} href="/collections">
            <Typography variant="title-sm">COLLECTIONS</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink className={styles.item} href="/drycleanonly">
            <Typography variant="title-sm">EDITORIAL</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
      <div className={styles.viewportContainer}>
        <NavigationMenuViewport className={styles.viewport} />
      </div>
    </NavigationMenu>
  );
};

export default MerchandisingMenu;
